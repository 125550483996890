import { toast } from "react-toastify";
import { errorMessages } from "lib/utils/constants";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  captureDisbursementActivityStateDetails,
  fetchDisbursementActivityStateDetails,
  updateCurrentDisbursementActivityDetails,
  updateIsLoading,
} from "components/DisbursementApplicationLeadDetails/DisbursementActivityState/redux/DisbursementActivityStateSlice";
import {
  requestCaptureDisbursementActivityState,
  requestDisbursementActivityStateDetails,
} from "components/DisbursementApplicationLeadDetails/DisbursementActivityState/redux/DisbursementActivityStateApi";
import { mutateFetchedValues } from "components/DisbursementApplicationLeadDetails/DisbursementActivityState/redux/DisbursementActivityStateHelper";

function* handleFetchDisbursementActivityStateDetails(action) {
  try {
    yield put(updateIsLoading(true));
    const applicationSlug = action.payload;
    const res = yield call(
      requestDisbursementActivityStateDetails,
      applicationSlug
    );
    if (res?.data?.success) {
      yield put(
        updateCurrentDisbursementActivityDetails(
          mutateFetchedValues(res?.data?.data)
        )
      );
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsLoading(false));
  }
}

function* handleCaptureDisbursementActivityStateDetails(action) {
  try {
    yield put(updateIsLoading(true));
    const { slug } = action.payload || {};
    const res = yield call(
      requestCaptureDisbursementActivityState,
      action.payload
    );
    yield put(updateIsLoading(false));
    if (res?.data?.success) {
      toast.success("Updated Successfully");
      yield put(fetchDisbursementActivityStateDetails(slug));
    } else {
      toast.error(res?.data?.message || errorMessages.SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.error(err);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsLoading(false));
  }
}

function* DisbursementActivityStateSaga() {
  yield takeLatest(
    fetchDisbursementActivityStateDetails,
    handleFetchDisbursementActivityStateDetails
  );
  yield takeLatest(
    captureDisbursementActivityStateDetails,
    handleCaptureDisbursementActivityStateDetails
  );
}

export default DisbursementActivityStateSaga;
