import { SamsResultType } from "components/CMS/CMSContent/OfacAndSamsTabContent/redux/SamsSectionConstants";

export const mapResultsResponse = (results) =>
  results?.map((res) => ({ id: res?.id })) || [];

export const mutatePayloadForRiskRating = ({ results, ...rest }) => ({
  ...rest,
  results: {
    ...results,
    sanctionResult: results?.sanctionsResult,
    corporateResult: [],
    sanctionCountryResult: [],
    legalResult: [],
  },
});

export const isResultTypeCompleted = (type, submitPayload) =>
  submitPayload?.[type]?.filter((res) => typeof res?.status !== "boolean")
    ?.length === 0;
