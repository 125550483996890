import { all } from "redux-saga/effects";
import CMSSaga from "../components/CMS/CMSSaga";
import BankAccountUpdateSaga from "components/DisbursementApplicationLeadDetails/BankAccountUpdate/redux/BankAccountUpdateSaga";
import DisbursementActivityStateSaga from "components/DisbursementApplicationLeadDetails/DisbursementActivityState/redux/DisbursementActivityStateSaga";

function* rootSaga() {
  yield all([
    CMSSaga(),
    BankAccountUpdateSaga(),
    DisbursementActivityStateSaga(),
  ]);
}

export default rootSaga;
