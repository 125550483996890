import { createAction, createSlice } from "@reduxjs/toolkit";

export const fetchDisbursementActivityStateDetails = createAction(
  "disbursementActivityState/fetchDisbursementActivityStateDetails"
);

export const captureDisbursementActivityStateDetails = createAction(
  "disbursementActivityState/captureDisbursementActivityStateDetails"
);

const disbursementActivityStateSlice = createSlice({
  name: "disbursementActivityState",
  initialState: {
    isLoading: false,
    currentDisbursementActivityDetails: null,
  },
  reducers: {
    updateIsLoading(state, action) {
      return { ...state, isLoading: action.payload };
    },
    updateCurrentDisbursementActivityDetails(state, action) {
      return { ...state, currentDisbursementActivityDetails: action.payload };
    },
  },
});

export const { updateIsLoading, updateCurrentDisbursementActivityDetails } =
  disbursementActivityStateSlice.actions;
export default disbursementActivityStateSlice.reducer;
