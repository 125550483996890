export const SamsResultType = {
  SANCTIONS_RESULT: "sanctionsResult",
  PEP_RESULT: "pepResult",
  AMS_RESULT: "amsResult",
};

export const SamsStatusType = {
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
  PENDING: "Pending"
};

export const RiskClassificationTypes = {
  HIGH: "high",
  MEDIUM: "medium",
  LOW: "low",
};

export const samsResultMeta = {
  [SamsResultType.SANCTIONS_RESULT]: {
    label: "Sanctions",
    columnHeadings: ["S.No", "Personal Information", "Score", "Actions"],
  },
  [SamsResultType.PEP_RESULT]: {
    label: "AMS Matches",
    columnHeadings: ["S.No", "Personal Information", "Score", "Actions"],
  },
  [SamsResultType.AMS_RESULT]: {
    label: "PEP Matches",
    columnHeadings: ["S.No", "Name", "News", "Tags", "Score", "Actions"],
  },
};

export const riskClassificationDataMapper = {
  [RiskClassificationTypes.HIGH]: {
    styling: "text-[#BA3B3B] border-[#BA3B3B]",
    iconSrc: "/assets/icons/cms/risk-danger.svg",
  },
  [RiskClassificationTypes.MEDIUM]: {
    styling: "text-[#AD7739] border-[#AD7739]",
    iconSrc: "/assets/icons/cms/risk-warning.svg",
  },
  [RiskClassificationTypes.LOW]: {
    styling: "text-[#527C43] border-[#527C43]",
    iconSrc: "/assets/icons/cms/risk-safe.svg",
  },
};

export const riskTableColNames = [
  "Parameter",
  "Matched",
  "Not Matched",
  "Risk Rating",
];

export const designationDetailsColumns = [
  { header: "Designation", key: "designation", shouldWordWrap: false },
  { header: "PEP Type", key: "designationPepType", shouldWordWrap: false },
  { header: "Start Date", key: "designationStartDate", shouldWordWrap: true },
  { header: "End Date", key: "designationEndDate", shouldWordWrap: true },
];

const commonScoresBlockKeys = [
  {
    key: "nameMatchScore",
    label: "Name",
  },
  {
    key: "dobMatchScore",
    label: "DOB",
  },
  {
    key: "addressMatchScore",
    label: "Address",
  },
  {
    key: "matchedId",
    label: "IDs",
  },
  {
    key: "overallScore",
    label: "SAMS Score",
  },
];

export const scoresBlockDataMapper = {
  [SamsResultType.SANCTIONS_RESULT]: commonScoresBlockKeys,
  [SamsResultType.PEP_RESULT]: commonScoresBlockKeys,
  [SamsResultType.AMS_RESULT]: [
    {
      key: "nameMatchScore",
      label: "Name Matching",
    },
    {
      key: "sentimentScore.score",
      label: "Sentiment",
    },
    {
      key: "sourceCredibilityScore",
      label: "Source Credibility",
    },
    {
      key: "overallScore",
      label: "Sams Score",
    },
  ],
};

export const existingSamsDetailsMapper = {
  APPLICANT: "applicantSamsDetails",
  COSIGNER: "cosignerSamsDetails",
  EXTRA_COSIGNER: "extraCosignerSamsDetails",
};
