import { createAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  personalDetails: null,
  academicDetails: null,
  addressDetails: null,
  workExperienceDetails: null,
  referenceDetails: null,
  universityDetails: null,
  intakeSeasons: null,
  collegeAndCampusPairs: null,
  additionalComments: null,
  coursesOptions: null,
  studentPersonalDocuments: [],
  studentEducationalDocuments: [],
  studentFinancialDocuments: [],
  studentUniversityDocuments: [],
  studentOtherDocuments: [],
  studentSanctionApprovalDocuments: [],
  applicationExtraDocuments: [],
  studentTopUpDocuments: [],
};

export const fetchStudentDetails = createAction(
  "studentDetails/studentDetails"
);
export const fetchIntakeSeasons = createAction(
  "studentDetails/fetchIntakeSeasons"
);
export const saveStudentDetails = createAction(
  "studentDetails/saveStudentDetails"
);
export const fetchCoursesOptions = createAction(
  "studentDetails/fetchCoursesOptions"
);
export const fetchCollegeAndCampusPairs = createAction(
  "studentDetails/fetchCollegeAndCampusPairs"
);

const studentDetailsSlice = createSlice({
  name: "studentDetails",
  initialState,
  reducers: {
    updatePersonalDetails(state, action) {
      const personalDetails = action.payload;
      return { ...state, personalDetails };
    },
    updateAcademicDetails(state, action) {
      const academicDetails = action.payload;
      return { ...state, academicDetails };
    },
    updateAddressDetails(state, action) {
      const addressDetails = action.payload;
      return { ...state, addressDetails };
    },
    updateWorkExperienceDetails(state, action) {
      const workExperienceDetails = action.payload;
      return { ...state, workExperienceDetails };
    },
    updateReferenceDetails(state, action) {
      const referenceDetails = action.payload;
      return { ...state, referenceDetails };
    },
    updateUniverseDetails(state, action) {
      const universityDetails = action.payload;
      return { ...state, universityDetails };
    },
    updateExtraDetails(state, action) {
      const extraDetails = action.payload;
      return { ...state, extraDetails };
    },
    updateUEOfferDetails(state, action) {
      const UEOfferDetails = action.payload;
      return { ...state, UEOfferDetails };
    },
    updateEligibilityDetails(state, action) {
      const eligibilityDetails = action.payload;
      return { ...state, eligibilityDetails };
    },
    updateUpdatedOfferDetails(state, action) {
      const updatedOfferDetails = action.payload;
      return { ...state, updatedOfferDetails };
    },
    updateIntakeSeasons(state, action) {
      const intakeSeasons = action.payload;
      return { ...state, intakeSeasons };
    },
    updateCollegeAndCampusPairs(state, action) {
      const collegeAndCampusPairs = action.payload;
      return { ...state, collegeAndCampusPairs };
    },
    updateAdditionalComments(state, action) {
      const additionalComments = action.payload;
      return { ...state, additionalComments };
    },
    updateCoursesOptions(state, action) {
      const coursesOptions = action.payload;
      return { ...state, coursesOptions };
    },
    updatePersonalDiscussionDetails(state, action) {
      const personalDiscussionDetails = action.payload;
      return { ...state, personalDiscussionDetails };
    },
    updateApprovalDetails(state, action) {
      const approvalDetails = action.payload;
      return { ...state, approvalDetails };
    },
    updateStudentDocuments(state, action) {
      return {
        ...state,
        studentPersonalDocuments: action?.payload?.studentPersonal,
        studentEducationalDocuments: action?.payload?.studentEducational,
        studentFinancialDocuments: action?.payload?.studentFinancial,
        studentUniversityDocuments: action?.payload?.studentUniversity,
        studentOtherDocuments: action?.payload?.studentOther,
        applicationExtraDocuments: action?.payload?.studentExtraDocument,
        studentTopUpDocuments: action?.payload?.studentTopUpDocuments,
        studentSanctionApprovalDocuments: action?.payload?.studentSanctionApprovalDocuments,
      };
    },
  },
});

export const {
  updatePersonalDetails,
  updateAcademicDetails,
  updateAddressDetails,
  updateWorkExperienceDetails,
  updateReferenceDetails,
  updateUniverseDetails,
  updateExtraDetails,
  updateUEOfferDetails,
  updateEligibilityDetails,
  updateUpdatedOfferDetails,
  updateIntakeSeasons,
  updateCollegeAndCampusPairs,
  updateAdditionalComments,
  updateCoursesOptions,
  updatePersonalDiscussionDetails,
  updateApprovalDetails,
  updateStudentDocuments,
} = studentDetailsSlice.actions;
export default studentDetailsSlice.reducer;
