import axios from "axios";
import { UserTypes } from "./CMSConstants";
import apolloClient from "apolloClient";
import { gql } from "@apollo/client";

export const requestAllocatedApplicationList = (casetype, pagenumber) => {
  return axios.request({
    method: "GET",
    url: `/api/route?path=cms/cmsapplications/${casetype}/${pagenumber}`,
  });
};

export const requestSearchAllocatedApplication = (query, pagenumber) => {
  const encoded = encodeURIComponent(query);
  return axios.request({
    method: "GET",
    url: `/api/route?path=cms/cmsapplicationsByQuery/${encoded}/${pagenumber}`,
  });
};

export const requestFilterApplications = ({ pagenumber, data }) => {
  return axios.request({
    method: "POST",
    url: `/api/route?path=cms/cmsapplicationsByFilter/${pagenumber}`,
    data,
  });
};

export const requestCmsDetails = ({
  userType,
  detailsType,
  applicationSlug,
}) => {
  return axios.request({
    method: "GET",
    url: `/api/route?path=camdetails/${
      userType ? `${userType}/` : ""
    }${detailsType}/${applicationSlug}`,
  });
};

export const requestSaveCmsDetails = ({
  userType,
  detailsType,
  data,
  applicationSlug,
  addressType,
}) => {
  return axios.request({
    method: userType === UserTypes.EXTRA_COSIGNER ? "POST" : "PUT",
    url: `/api/route?path=camdetails/${
      userType ? `${userType}/` : ""
    }${detailsType}/${applicationSlug}/${addressType ? addressType : ""}`,
    data,
  });
};

export const requestIntakeSeasons = (slug) =>
  axios.request({
    method: "GET",
    url: `/api/route?path=application_portal/all_seasons/${slug}`,
  });

export const requestCollegeAndCampuses = () =>
  axios.request({
    method: "GET",
    url: `/api/route?path=course/v2/fetchCampus/USA`,
  });

export const requestCoursesOptions = (campusId) =>
  axios.request({
    method: "GET",
    url: `/api/route?path=course/fetchCourse/${campusId}`,
  });

export const requestApplicationSeenStatusUpdate = (slug) => {
  return axios.request({
    method: "PATCH",
    url: `/api/route?path=cms/camseen/${slug}`,
  });
};

export const requestCMSInitialDetails = (casetype, pagenumber, query) => {
  return axios.request({
    method: "GET",
    url: `/api/cms/getCMSInitialDetails?casetype=${casetype}&pagenumber=${pagenumber}&searchQuery=${query}`,
  });
};

export const requestTeamMembers = () =>
  axios.request({
    method: "GET",
    url: `/api/route?path=cms/creditTeam`,
  });

export const requestMarkMemberAvailability = ({ opsDataId, data }) =>
  axios.request({
    method: "PUT",
    url: `/api/route?path=cms/availability/${opsDataId}`,
    data,
  });

export const requestReassignApplication = (applicationSlug, opsUserId) =>
  axios.request({
    method: "PUT",
    url: `/api/route?path=camdetails/reassignApplication/${applicationSlug}/${opsUserId}`,
  });

export const requestRejectApplication = ({ applicationSlug, data }) =>
  axios.request({
    method: "PUT",
    url: `/api/route?path=camdetails/rejectApplication/${applicationSlug}`,
    data,
  });

export const requestApproveApplication = ({ applicationSlug, data }) =>
  axios.request({
    method: "PUT",
    url: `/api/route?path=camdetails/approveApplication/${applicationSlug}`,
    data,
  });

export const requestSendApplicationForReview = ({ applicationSlug, data }) =>
  axios.request({
    method: "PUT",
    url: `/api/route?path=camdetails/sendForReview/${applicationSlug}`,
    data,
  });

export const requestRunDedupe = (applicationSlug) =>
  axios.request({
    method: "PUT",
    url: `/api/route?path=camdetails/dedupe/${applicationSlug}`,
  });

export const requestGenerateCam = (slug) => {
  return axios.request({
    method: "POST",
    url: `/api/route?path=camdetails/create/${slug}`,
  });
};

export const requestDownloadCam = (slug) => {
  return axios.request({
    method: "GET",
    url: `/api/downloadDoc?path=camdetails/generate-cam-document/${slug}`,
    responseType: "blob",
  });
};

export const requestMaxApprovalLimit = () =>
  axios.request({
    method: "GET",
    url: "/api/route?path=cms/max-approval-limit",
  });

export const requestApprovalCTAText = (slug) =>
  axios.get(`/api/route?path=camdetails/approval-cta/${slug}`);

export const requestApplicationDetails = (slug) =>
  apolloClient.query({
    query: gql`
      query ($slug: String!) {
        application(slug: $slug) {
          extraCosignerAttempt
        }
      }
    `,
    variables: { slug },
  });

export const requestSamsSearchResults = (data) =>
  axios.post(`/api/route?path=sams/name-screening`, data);

export const requestSamsRiskRating = (data) => {
  return axios.post(
    `/api/route?path=sams/calculate-risk-rating/${data?.slug}/${data?.userType}/${data?.results?.searchId}`,
    data
  );
};

export const requestSamsSubmitVerdict = (data) =>
  axios.post(
    `/api/route?path=sams/submit-verdict/${data?.slug}/${data?.userType}/${data?.searchId}`,
    data?.riskRatingResult
  );

export const requestGenerateSamsReport = (data) =>
  axios.get(
    `/api/route?path=sams/generate-report/${data?.slug}/${data?.userType}/${data?.searchId}`
  );

export const requestSamsDetails = (slug) =>
  axios.get(`/api/route?path=camdetails/sams-details/${slug}`);
