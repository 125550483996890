import { toast } from "react-toastify";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { errorMessages } from "../../../../../../lib/utils/constants";
import {
  requestDocumentBlobUrl,
  requestUserDocuments,
} from "./documentSectionApi";
import { updateCosignerDocuments } from "../../CAMSection/Forms/CosignerDetails/redux/cosignerDetailsSlice";
import { updateStudentDocuments } from "../../CAMSection/Forms/StudentDetails/redux/studentDetailsSlice";
import { mutateDocumentsData } from "./documentSectionHelper";
import {
  fetchDocumentBlobUrl,
  fetchUserDocuments,
  updateDocumentBlobUrl,
} from "./documentSectionSlice";

function* handleFetchDocumentBlobUrl(action) {
  try {
    const res = yield call(requestDocumentBlobUrl, action?.payload?.url);
    if (res && res?.data && res?.data?.size > 0) {
      const file = new Blob([res?.data], {
        type: "application/pdf",
      });
      const blobUrlForView = URL.createObjectURL(file);
      yield put(
        updateDocumentBlobUrl({
          blobUrl: blobUrlForView,
          fileType: action?.payload?.fileType,
          password: action?.payload?.password,
        })
      );
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* handleFetchUserDocuments(action) {
  try {
    const res = yield call(requestUserDocuments, action?.payload);
    if (res?.data?.success) {
      const {
        studentPersonal,
        studentEducational,
        studentFinancial,
        studentUniversity,
        studentOther,
        studentSanctionApprovalDocuments,
        cosignerPersonal,
        cosignerFinancial,
        cosignerOther,
        cosignerSanctionApprovalDocuments,
        extraCosignerPersonal,
        extraCosignerFinancial,
        extraCosignerOther,
        extraCosignerSanctionApprovalDocuments,
        studentExtraDocument,
        studentTopUpDocuments,
      } = mutateDocumentsData(res);
      yield all([
        put(
          updateStudentDocuments({
            studentPersonal,
            studentEducational,
            studentFinancial,
            studentUniversity,
            studentOther,
            studentExtraDocument,
            studentTopUpDocuments,
            studentSanctionApprovalDocuments,
          })
        ),
        put(
          updateCosignerDocuments({
            cosignerPersonal,
            cosignerFinancial,
            cosignerOther,
            cosignerSanctionApprovalDocuments,
            extraCosignerPersonal,
            extraCosignerFinancial,
            extraCosignerOther,
            extraCosignerSanctionApprovalDocuments,
          })
        ),
      ]);
    } else {
      toast?.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.FAILED_TO_FETCH);
  }
}

function* CMSLayoutSaga() {
  yield takeEvery(fetchDocumentBlobUrl, handleFetchDocumentBlobUrl);
  yield takeLatest(fetchUserDocuments, handleFetchUserDocuments);
}
export default CMSLayoutSaga;
