import {
  DisbursementActivityState,
  DisbursementBlockingReason,
  DisbursementBlockingReasonDetailMapper,
  DisbursementReasonValueFieldMapper,
} from "components/DisbursementApplicationLeadDetails/DisbursementActivityState/constants";
import { docKeyNames } from "DataMapper/docKeyNames";

export const mutateFetchedValues = (fetchedData) => {
  const {
    currentActivityState,
    latestAdditionalComments,
    latestReasonName,
    latestReasonValue,
  } = fetchedData || {};
  const transformedValues = {
    activityState: currentActivityState,
    ...(currentActivityState === DisbursementActivityState.DEACTIVATE && {
      additionalComments: latestAdditionalComments,
      reasonName: latestReasonName,
    }),
  };

  const reasonValueMapping = {
    [DisbursementBlockingReason.NON_FUNDED_UNIVERSITY]: () => {
      const [universityName, courseName] = latestReasonValue?.split("-");
      return { universityName, courseName };
    },
    [DisbursementBlockingReason.POOR_ACADEMICS]: () => ({
      cgpa: latestReasonValue,
    }),
    [DisbursementBlockingReason.DISCONTINUED_MASTERS]: () => ({
      locationStatus: latestReasonValue,
    }),
    [DisbursementBlockingReason.FRAUDULENT_DOCUMENTS_SUBMITTED]: () => ({
      uploadedDocType: latestReasonValue,
    }),
  };

  return {
    ...transformedValues,
    ...(currentActivityState === DisbursementActivityState.DEACTIVATE &&
      reasonValueMapping[latestReasonName]?.()),
  };
};

export const mutateCapturedValues = (values, applicationSlug) => {
  if (values?.activityState === DisbursementActivityState.ACTIVATE) {
    return {
      slug: applicationSlug,
      activityState: values?.activityState,
    };
  }

  const reasonFieldMapper =
    DisbursementReasonValueFieldMapper[values?.reasonName] || [];
  const reasonValue =
    reasonFieldMapper.length > 1
      ? `${values[reasonFieldMapper[0]]}-${values[reasonFieldMapper[1]]}`
      : values[reasonFieldMapper[0]];

  const fraudulentDocument = values?.fraudulentDocument?.map((doc) => ({
    ...doc,
    keyName: docKeyNames.FRAUD_DOCUMENT,
  }));

  return {
    ...values,
    slug: applicationSlug,
    reasonDetail: DisbursementBlockingReasonDetailMapper[values?.reasonName],
    reasonValue,
    ...(fraudulentDocument && { fraudulentDocument }),
  };
};
