import { createAction, createSlice } from "@reduxjs/toolkit";

export const fetchSamsSearchResults = createAction(
  "samsSection/fetchSamsSearchResults"
);
export const fetchSamsRiskRating = createAction(
  "samsSection/fetchSamsRiskRating"
);
export const submitSamsVerdict = createAction("samsSection/submitSamsVerdict");
export const generateSamsReport = createAction(
  "samsSection/generateSamsReport"
);
export const fetchSamsDetails = createAction("samsSection/fetchSamsDetails");

const initialUserState = {
  samsDetails: null,
  searchResults: null,
  isLoading: false,
  riskRatingResult: null,
  samsSubmitPayload: {
    sanctionResult: [],
    pepResult: [],
    amsResult: [],
  },
  isVerdictSubmitted: false,
  samsReportHtml: null,
};

const initialState = {
  APPLICANT: { ...initialUserState },
  COSIGNER: { ...initialUserState },
  EXTRA_COSIGNER: { ...initialUserState },
  samsDetails: null,
};

const samsSectionSlice = createSlice({
  name: "samsSection",
  initialState,
  reducers: {
    updateSearchResults(state, action) {
      const { userType, searchResults } = action.payload;
      return {
        ...state,
        [userType]: {
          ...state[userType],
          searchResults,
        },
      };
    },
    updateIsLoading(state, action) {
      const { userType, isLoading } = action.payload;
      return {
        ...state,
        [userType]: {
          ...state[userType],
          isLoading,
        },
      };
    },
    updateRiskRatingResults(state, action) {
      const { userType, ...rest } = action.payload;
      return {
        ...state,
        [userType]: {
          ...state[userType],
          riskRatingResult: rest,
        },
      };
    },
    updateSamsSubmitPayload(state, action) {
      const { userType, ...rest } = action.payload;
      return {
        ...state,
        [userType]: {
          ...state[userType],
          samsSubmitPayload: rest,
        },
      };
    },
    updateIsVerdictSubmitted(state, action) {
      const { userType, ...rest } = action.payload;
      return {
        ...state,
        [userType]: {
          ...state[userType],
          isVerdictSubmitted: rest,
        },
      };
    },
    updateSamsReportHtml(state, action) {
      const { userType, samsReportHtml } = action.payload;
      return {
        ...state,
        [userType]: {
          ...state[userType],
          samsReportHtml,
        },
      };
    },
    updateSamsDetails(state, action) {
      const samsDetails = action.payload;
      return { ...state, samsDetails };
    },
    reset: () => initialState,
  },
});

export const {
  updateSearchResults,
  updateIsLoading,
  updateRiskRatingResults,
  updateSamsSubmitPayload,
  updateIsVerdictSubmitted,
  updateSamsReportHtml,
  updateSamsDetails,
  reset,
} = samsSectionSlice.actions;
export default samsSectionSlice.reducer;
