import {
  requestGenerateSamsReport,
  requestSamsDetails,
  requestSamsRiskRating,
  requestSamsSearchResults,
  requestSamsSubmitVerdict,
} from "components/CMS/shared/CMSApis";
import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import { errorMessages } from "lib/utils/constants";
import {
  fetchSamsDetails,
  fetchSamsRiskRating,
  fetchSamsSearchResults,
  generateSamsReport,
  submitSamsVerdict,
  updateIsLoading,
  updateIsVerdictSubmitted,
  updateRiskRatingResults,
  updateSamsDetails,
  updateSamsReportHtml,
  updateSamsSubmitPayload,
  updateSearchResults,
} from "components/CMS/CMSContent/OfacAndSamsTabContent/redux/SamsSectionSlice";
import {
  mapResultsResponse,
  mutatePayloadForRiskRating,
} from "components/CMS/CMSContent/OfacAndSamsTabContent/redux/SamsSectionHelper";

function* handleFetchSamsSearchResults(action) {
  const { userType } = action.payload || {};
  try {
    yield put(updateIsLoading({ userType, isLoading: true }));
    const res = yield call(requestSamsSearchResults, {
      ...action.payload,
    });
    const { success, data: resultData } = res?.data || {};
    if (success && resultData?.status === "success") {
      const { sanctionsResult, pepResult, amsResult, searchId } =
        resultData?.data || {};
      yield put(
        updateSearchResults({
          searchResults: resultData?.data,
          userType,
        })
      );
      yield put(
        updateSamsSubmitPayload({
          userType,
          searchId,
          sanctionsResult: mapResultsResponse(sanctionsResult),
          pepResult: mapResultsResponse(pepResult),
          amsResult: mapResultsResponse(amsResult),
        })
      );
      toast.success("Successfully initiated Search.");
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  } finally {
    yield put(updateIsLoading({ userType, isLoading: false }));
  }
}

function* handleFetchSamsRiskRating(action) {
  try {
    const { userType } = action.payload;
    console.log(action.payload)
    const res = yield call(
      requestSamsRiskRating,
      mutatePayloadForRiskRating(action.payload)
    );
    if (res?.data?.success) {
      yield put(updateRiskRatingResults({ userType, ...res?.data?.data }));
      toast.success("Risk rating successfully generated.");
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  }
}

function* handleFetchSamsDetails(action) {
  try {
    const res = yield call(requestSamsDetails, action.payload);
    if (res?.data?.success) {
      yield put(updateSamsDetails(res?.data?.data));
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  }
}

function* handleSubmitSamsVerdict(action) {
  try {
    const { userType } = action.payload;
    const res = yield call(requestSamsSubmitVerdict, {
      ...action.payload,
    });
    if (res?.data?.success) {
      yield put(
        updateIsVerdictSubmitted({ userType, isVerdictSubmitted: true })
      );
      toast.success("Verdict submitted successfully.");
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  }
}

function* handleGenerateSamsReport(action) {
  try {
    const { userType } = action.payload;
    const res = yield call(requestGenerateSamsReport, {
      ...action.payload,
    });
    if (res?.data?.success) {
      yield put(
        updateSamsReportHtml({ userType, samsReportHtml: res?.data?.data })
      );
      toast.success("Report generated successfully.");
    } else {
      toast.error(res?.data?.message || errorMessages?.SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    console.error(error);
    toast.error(errorMessages?.SOMETHING_WENT_WRONG);
  }
}

function* SamsSectionSaga() {
  yield takeLatest(fetchSamsSearchResults, handleFetchSamsSearchResults);
  yield takeLatest(fetchSamsRiskRating, handleFetchSamsRiskRating);
  yield takeLatest(fetchSamsDetails, handleFetchSamsDetails);
  yield takeLatest(submitSamsVerdict, handleSubmitSamsVerdict);
  yield takeLatest(generateSamsReport, handleGenerateSamsReport);
}
export default SamsSectionSaga;
