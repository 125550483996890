import { all } from "redux-saga/effects";
import CosignerDetailsSaga from "./CMSContent/CAMTabContent/CAMSection/Forms/CosignerDetails/redux/cosignerDetailsSaga";
import OtherDetailsSaga from "./CMSContent/CAMTabContent/CAMSection/Forms/OtherDetails/redux/otherDetailsSaga";
import StudentDetailsSaga from "./CMSContent/CAMTabContent/CAMSection/Forms/StudentDetails/redux/studentDetailsSaga";
import CAMSectionSaga from "./CMSContent/CAMTabContent/CAMSection/redux/CAMSectionSaga";
import SummaryContentSaga from "./CMSContent/SummaryTabContent/redux/SummaryContentSaga";
import CMSLayoutSaga from "./CMSLayout/redux/CMSLayoutSaga";
import DocumentRequestSaga from "./CMSContent/DocumentRequestTabContent/redux/documentRequestSaga";
import DocumentSectionSaga from "./CMSContent/CAMTabContent/DocumentsSection/redux/documentSectionSaga";
import StudentTopUpDetailsSaga from "components/CMS/CMSContent/TopUpTabContent/redux/topUpSaga";
import SamsSectionSaga from "components/CMS/CMSContent/OfacAndSamsTabContent/redux/SamsSectionSaga";

function* CMSSaga() {
  yield all([
    CMSLayoutSaga(),
    StudentDetailsSaga(),
    CosignerDetailsSaga(),
    SummaryContentSaga(),
    CAMSectionSaga(),
    OtherDetailsSaga(),
    DocumentRequestSaga(),
    DocumentSectionSaga(),
    StudentTopUpDetailsSaga(),
    SamsSectionSaga(),
  ]);
}

export default CMSSaga;
