/**********  how it works page - start **********/

export const creditRejectReasons = [
  {
    label: "Student academics not meeting norms-backlogs",
    value: "Student academics not meeting norms-backlogs",
  },
  {
    label: "Student age",
    value: "Student age",
  },
  {
    label: "Student credit history issues",
    value: "Student credit history issues",
  },
  {
    label: "Student's profile, work experience etc",
    value: "Student's profile, work experience etc",
  },
  {
    label: "Student planning for other college not in our list",
    value: "Student planning for other college not in our list",
  },
  {
    label: "Conditional admit/prerequisites",
    value: "Conditional admit/prerequisites",
  },
  {
    label: "Coborrower location OGL",
    value: "Coborrower location OGL",
  },
  {
    label: "Coborrower credit history issues",
    value: "Coborrower credit history issues",
  },
  {
    label: "Coborrower overleveraged",
    value: "Coborrower overleveraged",
  },
  {
    label: "Coborrower has no income",
    value: "Coborrower has no income",
  },
  {
    label: "Co Borrower staying in a negative/ community dominating area",
    value: "Co Borrower staying in a negative/ community dominating area",
  },
  {
    label: "Coborrower profile not meeting income norms",
    value: "Coborrower profile not meeting income norms",
  },
  {
    label: "Coborrower age",
    value: "Coborrower age",
  },
  {
    label: "Loan amount not meeting norms",
    value: "Loan amount not meeting norms",
  },
  {
    label: "Negative FI/TVR /Negative Reference/Negative Feedback",
    value: "Negative FI/TVR /Negative Reference/Negative Feedback",
  },
  {
    label: "No Additional Co Borrower",
    value: "No Additional Co Borrower",
  },
  {
    label: "Negative PD",
    value: "Negative PD",
  },
  {
    label: "Plan dropped by applicant",
    value: "Plan dropped by applicant",
  },
  {
    label: "Student processing application with a Competitor",
    value: "Student processing application with a Competitor",
  },
  {
    label: "Student not responding to calls",
    value: "Student not responding to calls",
  },
  {
    label: "Other reasons",
    value: "Other reasons",
  },
];
export const processSteps = {
  application: [
    {
      title: "Online Application",
      content:
        "Complete application online - See your offer in 10 mins (no documents needed)",
      imgSrc: "/assets/images/online-application.svg",
    },
    {
      title: "Claim Offer Online",
      content: "Accept the offer. Choose the amount and tenure of the loan.",
      imgSrc: "/assets/images/claim-offer.svg",
    },
    {
      title: "Personal Discussion",
      content:
        "The credit team will have a video call with you to know you better and answer any questions you may have.",
      imgSrc: "/assets/images/personal-discussion.svg",
    },
  ],
  sanction: [
    {
      title: "Sanction Letter",
      content:
        "Download your official Sanction letter (absolutely free!). This letter can be used to get your I-20 & visa",
      imgSrc: "/assets/images/download.svg",
    },
    {
      title: "Upload I-20 & visa",
      content:
        "Once you get your I-20 and visa, upload them on your application profile page.",
      imgSrc: "/assets/images/upload.svg",
    },
    {
      title: "Sign Loan Contract",
      content:
        "You will get the loan agreement on your registered email. Both you and Leap Finance will sign the contract.",
      imgSrc: "/assets/images/sign-contract.svg",
    },
  ],
  disbursement: [
    {
      title: "Processing fee",
      content: "We charge a processing fee of 2% when you request for funds.",
      imgSrc: "/assets/images/credit-card.svg",
    },
    {
      title: "Fund Transfer",
      content:
        "The tuition and living expenses are directly sent to the university before you head to the US. You can claim your living expenses from the university.",
      imgSrc: "/assets/images/payment-method.svg",
    },
    {
      title: "Repayments",
      content:
        "Pay partial monthly interest during the course which helps to build your credit score. Full EMI repayments on your loan start 6 months after you graduate.",
      imgSrc: "/assets/images/checkmark.svg",
    },
  ],
};

export const canadaProcessSteps = {
  application: [
    {
      title: "Online Application",
      content:
        "Complete application online - See your offer in 10 mins (no documents needed)",
      imgSrc: "/assets/images/online-application.svg",
    },
    {
      title: "Claim Offer Online",
      content: "Accept the offer. Choose the amount of the loan.",
      imgSrc: "/assets/images/claim-offer.svg",
    },
    {
      title: "Personal Discussion",
      content:
        "The credit team will have a video call with you to know you better and answer any questions you may have.",
      imgSrc: "/assets/images/personal-discussion.svg",
    },
  ],
  sanction: [
    {
      title: "Sanction Letter",
      content:
        "Download your official Sanction letter (absolutely free!). This letter can be used to get your visa",
      imgSrc: "/assets/images/download.svg",
    },
    {
      title: "Upload visa",
      content:
        "Once you get your visa, upload them on your application profile page.  ",
      imgSrc: "/assets/images/upload.svg",
    },
    {
      title: "Sign Loan Contract",
      content:
        "You will get the loan agreement on your registered email. Both you and Leap Finance/Leap Finance Partner will sign the contract.",
      imgSrc: "/assets/images/sign-contract.svg",
    },
  ],
  disbursement: [
    {
      title: "Processing fee",
      content: "We charge a processing fee of 2% when you request for funds.",
      imgSrc: "/assets/images/credit-card.svg",
    },
    {
      title: "Fund Transfer",
      content:
        "The tuition and living expenses are directly sent to the university before you head to Canada. You can claim your living expenses from the university.",
      imgSrc: "/assets/images/payment-method.svg",
    },
    {
      title: "Repayments",
      content:
        "Pay partial monthly interest/full EMI during the course which helps to build your credit score. Full EMI repayments on your loan start 12 months after you graduate (conditional).",
      imgSrc: "/assets/images/checkmark.svg",
    },
  ],
};

/* faq data for different pages to be added here- start */
export const Faq = {
  loan: {
    title: "Education Loan FAQs for Abroad Studies",
    description:
      "Education Loan Faqs - Get answers to most frequently asked questions about Aborad Education Loans or contact us for more detail.",
    bannerImage: "/assets/images/index/faq-educationloan-banner.svg",
    faqCategory: [
      { name: "Eligibility", icon: "/assets/images/index/faq-user-icon.svg" },
      { name: "Process", icon: "/assets/images/index/icon-process.png" },
      { name: "Terms", icon: "/assets/images/index/terms.svg" },
      {
        name: "COVID-19",
        icon: "/assets/images/index/icon-miscellaneous.png",
      },
    ],
    introText: "Welcome!",
    paraText: <h1>How can we help you?</h1>,
    googleSheetUrl:
      "https://spreadsheets.google.com/feeds/cells/1hUp6iQpiKDFnA_XjXoljYeS4KRxVxcDzUahYGQTvTN4/1/public/full?alt=json",
  },
  bank: {
    title: "US Bank account for Abroad Studies",
    description:
      "Educ Faqs - Get answers to most frequently asked questions about Aborad Education Loans or contact us for more detail.",
    bannerImage: "/assets/images/index/faq-bankaccount-banner.png",
    faqCategory: [
      {
        name: "Get started",
        icon: "/assets/images/index/faq-get-started-icon.svg",
      },
      {
        name: "Transactions",
        icon: "/assets/images/index/faq-transfer-icon.svg",
      },
      {
        name: "Account Info",
        icon: "/assets/images/index/faq-account-icon.svg",
      },
      {
        name: "Security",
        icon: "/assets/images/index/faq-security-icon.svg",
      },
    ],
    introText: <h1>US Bank Account Faqs</h1>,
    paraText: "How can we help you?",
    googleSheetUrl:
      "https://spreadsheets.google.com/feeds/cells/1SndE_1ssuWS72fCEDx-H7vXBql3A4GSoi51bQdRBBcA/1/public/full?alt=json",
  },
};

// Bank Account Stage Mapping

export const baStageMap = {
  BA_PERSONAL_INFO_CAPTURE: "personal_info",
  BA_DOCS_CAPTURE: "documents",
  BA_DISCLOSURE_PENDING: "disclosure",
  BA_VISA_INCOMPLETE: "visa_incomplete",
  BA_SENT_TO_SALES: "application_complete",
  BA_HELD_BY_SALES_DOCUMENT_AWAITED: "application_complete",
  BA_SENT_TO_PARTNER: "application_complete",
  BA_RETURN_BY_PARTNER: "application_complete",
  BA_ACCOUNT_OPENED: "application_complete",
  BA_SALES_REJECTED: "application_declined",
  BA_REJECTED_AFTER_PARTNER_RETURN: "application_declined",
};

// Indian States

export const indianStates = [
  {
    label: "Andhra Pradesh",
    value: "andhra_pradesh",
  },
  {
    label: "Arunachal Pradesh",
    value: "arunachal_pradesh",
  },
  {
    label: "Assam",
    value: "assam",
  },
  {
    label: "Bihar",
    value: "bihar",
  },
  {
    label: "Chhattisgarh",
    value: "chhattisgarh",
  },
  {
    label: "Goa",
    value: "goa",
  },
  {
    label: "Gujarat",
    value: "gujarat",
  },
  {
    label: "Haryana",
    value: "haryana",
  },
  {
    label: "Himachal Pradesh",
    value: "himachal_pradesh",
  },
  {
    label: "Jharkhand",
    value: "jharkhand",
  },
  {
    label: "Karnataka",
    value: "karnataka",
  },
  {
    label: "Kerala",
    value: "kerala",
  },
  {
    label: "Madhya Pradesh",
    value: "madhya_pradesh",
  },
  {
    label: "Maharashtra",
    value: "maharashtra",
  },
  {
    label: "Manipur",
    value: "manipur",
  },
  {
    label: "Meghalaya",
    value: "meghalaya",
  },
  {
    label: "Mizoram",
    value: "mizoram",
  },
  {
    label: "Nagaland",
    value: "nagaland",
  },
  {
    label: "Odisha",
    value: "odisha",
  },
  {
    label: "Punjab",
    value: "punjab",
  },
  {
    label: "Rajasthan",
    value: "rajasthan",
  },
  {
    label: "Sikkim",
    value: "sikkim",
  },
  {
    label: "Tamil Nadu",
    value: "tamil_nadu",
  },
  {
    label: "Telangana",
    value: "telangana",
  },
  {
    label: "Tripura",
    value: "tripura",
  },
  {
    label: "Uttarakhand",
    value: "uttarakhand",
  },
  {
    label: "Uttar Pradesh",
    value: "uttar_pradesh",
  },
  {
    label: "West Bengal",
    value: "west_bengal",
  },
  {
    label: "Andaman & Nicobar Islands",
    value: "andaman_and_nicobar_islands",
  },
  {
    label: "Chandigarh",
    value: "chandigarh",
  },
  {
    label: "Dadar and Nagar Haveli",
    value: "dadar_and_nagar_haveli",
  },
  {
    label: "Daman and Diu",
    value: "daman_and_diu",
  },
  {
    label: "Delhi",
    value: "delhi",
  },
  {
    label: "Jammu & Kashmir",
    value: "jammu_and_kashmir",
  },
  {
    label: "Ladakh",
    value: "ladakh",
  },
  {
    label: "Lakshadweep",
    value: "lakshadweep",
  },
  {
    label: "Punducherry",
    value: "punducherry",
  },
];

// US States

export const usStates = [
  {
    label: "Alabama",
    value: "alabama",
  },
  {
    label: "Alaska",
    value: "alaska",
  },
  {
    label: "Arizona",
    value: "arizona",
  },
  {
    label: "Arkansas",
    value: "arkansas",
  },
  {
    label: "California",
    value: "california",
  },
  {
    label: "Colorado",
    value: "colorado",
  },
  {
    label: "Connecticut",
    value: "connecticut",
  },
  {
    label: "Delaware",
    value: "delaware",
  },
  {
    label: "Florida",
    value: "florida",
  },
  {
    label: "Georgia",
    value: "georgia",
  },
  {
    label: "Hawaii",
    value: "hawaii",
  },
  {
    label: "Idaho",
    value: "idaho",
  },
  {
    label: "Illinois",
    value: "illinois",
  },
  {
    label: "Indiana",
    value: "indiana",
  },
  {
    label: "Iowa",
    value: "iowa",
  },
  {
    label: "Kansas",
    value: "kansas",
  },
  {
    label: "Kentucky",
    value: "kentucky",
  },
  {
    label: "Louisiana",
    value: "louisiana",
  },
  {
    label: "Maine",
    value: "maine",
  },
  {
    label: "Maryland",
    value: "maryland",
  },
  {
    label: "Massachusetts",
    value: "massachusetts",
  },
  {
    label: "Michigan",
    value: "michigan",
  },
  {
    label: "Minnesota",
    value: "minnesota",
  },
  {
    label: "Mississippi",
    value: "mississippi",
  },
  {
    label: "Missouri",
    value: "missouri",
  },
  {
    label: "Montana",
    value: "montana",
  },
  {
    label: "Nebraska",
    value: "nebraska",
  },
  {
    label: "Nevada",
    value: "nevada",
  },
  {
    label: "New Hampshire",
    value: "new_hampshire",
  },
  {
    label: "New Jersey",
    value: "new_jersey",
  },
  {
    label: "New Mexico",
    value: "new_mexico",
  },
  {
    label: "New York",
    value: "new_york",
  },
  {
    label: "North Carolina",
    value: "north_carolina",
  },
  {
    label: "North Dakota",
    value: "north_dakota",
  },
  {
    label: "Ohio",
    value: "ohio",
  },
  {
    label: "Oklahoma",
    value: "oklahoma",
  },
  {
    label: "Oregon",
    value: "oregon",
  },
  {
    label: "Pennsylvania",
    value: "pennsylvania",
  },
  {
    label: "Rhode Island",
    value: "rhode_island",
  },
  {
    label: "South Carolina",
    value: "south_carolina",
  },
  {
    label: "South Dakota",
    value: "south_dakota",
  },
  {
    label: "Tennessee",
    value: "tennessee",
  },
  {
    label: "Texas",
    value: "texas",
  },
  {
    label: "Utah",
    value: "utah",
  },
  {
    label: "Vermont",
    value: "vermont",
  },
  {
    label: "Virginia",
    value: "virginia",
  },
  {
    label: "Washington",
    value: "washington",
  },
  {
    label: "West Virginia",
    value: "west_virginia",
  },
  {
    label: "Wisconsin",
    value: "wisconsin",
  },
  {
    label: "Wyoming",
    value: "wyoming",
  },
];

//EducationData

export const educationData = {
  header: (
    <p>
      International education loans for future leaders.{" "}
      <span className="text-primary">Like you!</span>
    </p>
  ),
  subHeader: <p>No Collateral | Online Process | 8.25%-10.25% APR</p>,
  steps: [
    {
      image: "/assets/images/bank-application/loan-step1.svg",
      title: "Step 1",
      subtitle: (
        <span>
          Select your college and course <br />
          <span className="text-gray2-300">Choose from the best</span>
        </span>
      ),
    },
    {
      image: "/assets/images/bank-application/loan-step2.svg",
      title: "Step 2",
      subtitle: (
        <span>
          Fill your form online <br />
          <span className="text-gray2-300">No boring paperwork</span>
        </span>
      ),
    },
    {
      image: "/assets/images/bank-application/loan-step3.svg",
      title: "Step 3",
      subtitle: (
        <span>
          Get your loan offer <br />
          <span className="text-gray2-300">
            Instant approval, 3 days sanction
          </span>
        </span>
      ),
    },
  ],
};

// Bank Data

export const bankData = {
  header: (
    <p>
      Thinking about <span className="text-primary">coolest</span> way to carry
      dollars to US?
    </p>
  ),
  subHeader: (
    <p>
      Open your US Bank Account while in India | Transfer money | Transact from
      Day 1 in US
    </p>
  ),
  steps: [
    {
      image: "/assets/images/bank-application/bank-step1.svg",
      title: "Step 1",
      subtitle: (
        <span>
          Fill your form online
          <br />
          Takes only 3 mins
        </span>
      ),
    },
    {
      image: "/assets/images/bank-application/bank-step2.svg",
      title: "Step 2",
      subtitle: (
        <span>
          Upload documents &<br />
          Get your bank account
        </span>
      ),
    },
    {
      image: "/assets/images/bank-application/bank-step3.svg",
      title: "Step 3",
      subtitle: (
        <span>
          Transfer money &<br />
          Start Transacting
        </span>
      ),
    },
  ],
};

export const cosignerDocuments = [
  "pan",
  "resdential_proof",
  "it_return1",
  "bankStatementCoSigner",
  "gst_return",
  "extraDocument",
];

export const disbursementStages = {
  STUDENT_REQUEST_INITIATED: "STUDENT_REQUEST_INITIATED",
  DOCUMENTS_UPLOAD: "DOCUMENTS_UPLOAD",
  CREDIT_MANAGER_APPROVAL: "CREDIT_MANAGER_APPROVAL",
  REUPLOAD_TUITION_INVOICE: "REUPLOAD_TUITION_INVOICE",
  OFFER_UPDATED: "OFFER_UPDATED",
  OFFER_REJECTED: "OFFER_REJECTED",
  FLYWIRE_DETAILS: "FLYWIRE_DETAILS",
  INITIATE_DISBURSEMENT: "INITIATE_DISBURSEMENT",
  FLYWIRE_REJECTED: "FLYWIRE_REJECTED",
  DISBURSEMENT_PENDING: "DISBURSEMENT_PENDING",
  DISBURSEMENT_DONE: "DISBURSEMENT_DONE",
  CREDIT_REJECTED: "CREDIT_REJECTED",
  ALL: "ALL",
};

export const multiUploadDisbursementDocs = [
  "fiReport",
  "bankStatementApplicant",
  "rcuReport",
];

export const disbursementLoanDetailsSectionTablesData = (loanDetails) => {
  return [
    {
      key: "userDetails",
      tableTitle: "User Details",
      columns: [
        {
          dataIndex: "appId",
          key: "appId",
          title: "App No",
        },
        {
          dataIndex: "name",
          key: "name",
          title: "Name",
        },
        {
          dataIndex: "collegeName",
          key: "collegeName",
          title: "College name",
        },
        {
          dataIndex: "phone",
          key: "phone",
          title: "Contact Details",
        },
        {
          dataIndex: "cgpa",
          key: "cgpa",
          title: "CGPA",
        },
      ],
    },
    {
      key: "loanAmountDetails",
      tableTitle: "Loan Amount Details",
      columns: [
        {
          dataIndex: "originalApprovedOffer",
          key: "originalApprovedOffer",
          title: "Approved Offer",
        },
        loanDetails?.loanAmountDetails?.topupAmount && {
          dataIndex: "topupAmount",
          key: "topupAmount",
          title: "Top Up Amount",
        },
        loanDetails?.loanAmountDetails?.totalAmount && {
          dataIndex: "totalAmount",
          key: "totalAmount",
          title: "Total Amount",
        },
        {
          dataIndex: "roi",
          key: "roi",
          title: "ROI",
        },
        {
          dataIndex: "loanTenure",
          key: "loanTenure",
          title: "Tenure",
        },
      ].filter((field) => !!field),
    },
    {
      key: "disbursementDetails",
      tableTitle: "Disbursement Details",
      columns: [
        {
          dataIndex: "tuitionFeeDisbursed",
          key: "tuitionFeeDisbursed",
          title: "Tuition fee",
        },
        {
          dataIndex: "personalExpenseDisbursed",
          key: "personalExpenseDisbursed",
          title: "Living Expense",
        },
        {
          dataIndex: "remainingAmount",
          key: "remainingAmount",
          title: "Remaining Amount",
        },
        {
          dataIndex: "totalDisbursedAmount",
          key: "totalDisbursedAmount",
          title: "Total",
        },
      ],
    },
    {
      key: "loanTransactionDetails",
      tableTitle: "Loan Transaction Details",
      columns: [
        {
          dataIndex: "amount",
          key: "amount",
          title: "Amount",
        },
        {
          dataIndex: "creditedTo",
          key: "creditedTo",
          title: "Credited To",
        },
        {
          dataIndex: "date",
          key: "date",
          title: "Date/Time",
        },
        {
          dataIndex: "type",
          key: "type",
          title: "Expense Type",
        },
      ],
    },
  ];
};

export const columnsForLeadTable = [
  {
    dataIndex: "leadAuthId",
    key: "leadAuthId",
    title: "Lead AuthId",
  },
  {
    dataIndex: "leadName",
    key: "leadName",
    title: "Lead Name",
  },
  {
    dataIndex: "leadEmail",
    key: "leadEmail",
    title: "Lead Email",
  },
  {
    dataIndex: "leadPhone",
    key: "leadPhone",
    title: "Lead Phone",
  },
];

export const columnsForDisbursementStateChangeDetails = [
  {
    field: "createdAt",
    headerName: "Date",
    sortable: false,
    flex: 1,
  },
  {
    field: "oldStage",
    headerName: "old stage",
    sortable: false,
    flex: 1,
  },
  {
    field: "newStage",
    headerName: "new stage",
    sortable: false,
    flex: 1,
  },
  {
    field: "userIdentifier",
    headerName: "user Identifier",
    sortable: false,
    flex: 1,
  },
  {
    field: "comment",
    headerName: "Comments",
    sortable: false,
    flex: 1,
  },
];

export const disbursementRequestStatus = {
  PENDING: "PENDING",
  CM_APPROVED: "CM_APPROVED",
  OFFER_UPDATED: "OFFER_UPDATED",
  STUDENT_REJECTED: "STUDENT_REJECTED",
  STUDENT_ACCEPTED: "STUDENT_ACCEPTED",
};

export const CreditFormDisplayFields = [
  "Name",
  "CreditScore",
  "CreditReportSource",
  "CreditScoreValidated",
  "CreditReportDocumentPassword",
];

export const UserTypes = {
  APPLICANT: "applicant",
  ACTIVE_COSIGNER: "activeCosigner",
  EXTRA_COSIGNER: "extraCosigner",
};

export const UserTypesForDocs = {
  APPLICANT: "APPLICANT",
  COSIGNER: "COSIGNER",
  EXTRA_COSIGNER: "EXTRA_COSIGNER",
};

export const ProcessingFeesColumns = [
  {
    dataIndex: "type",
    key: "type",
    title: "Type",
  },
  {
    dataIndex: "vendor",
    key: "vendor",
    title: "Payment Vendor",
  },
  {
    dataIndex: "status",
    key: "status",
    title: "Status",
    render: (value, record, index) => (
      <div
        className={`
          ${
            record.status === "Applied" || record.status === "Received"
              ? "bg-[#E9F1E6]"
              : "bg-[#CDCDCD]"
          } p-3 rounded-sm text-center
        `}
      >
        {record.status}
      </div>
    ),
  },
];

export const ProcessingFeesRowStyle = {
  borderBottom: "20px solid white",
};

export const BankAccountStages = {
  BA_PERSONAL_INFO_CAPTURE: "BA_PERSONAL_INFO_CAPTURE",
  BA_DOCS_CAPTURE: "BA_DOCS_CAPTURE",
  BA_HELD_BY_SALES_DOCUMENT_AWAITED: "BA_HELD_BY_SALES_DOCUMENT_AWAITED",
  BA_SENT_TO_SALES: "BA_SENT_TO_SALES",
  BA_SENT_TO_PARTNER: "BA_SENT_TO_PARTNERs",
};

export const BankDocumentStatus = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const flywireSupportedBanks = [
  { label: "CITI", value: "CITI" },
  { label: "JPMC", value: "JPMC" },
];

export const errorMessages = {
  SOMETHING_WENT_WRONG: "Something went wrong. Please contact developers!!",
  NOT_AUTHORIZED:
    "You are not authorized to view this page. Please contact developers!!",
  FAILED_TO_FETCH: "Failed to fetch data from server, contact tech team.",
  ACTION_NOT_ALLOWED: "This action is not allowed.",
  FILE_VIEW_FAILED: "Failed to view file (not found).",
};

export const ManualCSVUploadTypes = {
  RCUFI: "RCUFI",
  DISBURSEMENT: "DISBURSEMENT",
  BANKSTATUS: "BANKSTATUS",
  REFUND: "REFUND",
  CASHBACK: "CASHBACK",
  VERVENTTXN: "VERVENTTXN",
  WAREHOUSE: "WAREHOUSE",
  FLOW_TEMPLATE: "FLOW_TEMPLATE",
  PA_DETAILS: "PA_DETAILS",
  LOAN_FEE_DETAILS: "LOAN_FEE_DETAILS",
  NEGATIVE_INFORMATION_NOTICE: "NEGATIVE_INFORMATION_NOTICE",
  QUACKER_CONTACT: "QUACKER_CONTACT",
  VERVENT_LOAN_DAILY_REPORT: "VERVENT_LOAN_DAILY_REPORT",
  LOAN_CLOSURE_DETAILS: "LOAN_CLOSURE_DETAILS",
};

export const DOCUMENT_STATUS = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  PENDING: "PENDING",
};

export const dataGridCommonProps = {
  sx: {
    ".MuiDataGrid-cell:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-row:hover": {
      cursor: "pointer",
    },
    ".MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
      fontSize: "16px",
    },
  },
  disableSelectionOnClick: true,
  disableColumnFilter: true,
  disableColumnMenu: true,
  disableColumnSelector: true,
  showColumnRightBorder: true,
  showCellVerticalBorder: true,
  showColumnVerticalBorder: true,
  hideFooterSelectedRowCount: true,
  className: "font-sans",
  initialState: {
    pagination: { paginationModel: { pageSize: 10 } },
  },
  pageSizeOptions: [10, 25, 30, 50],
};
