export const DisbursementActivityState = {
  ACTIVATE: "ACTIVATE",
  DEACTIVATE: "DEACTIVATE",
};

export const DisbursementBlockingReason = {
  NON_FUNDED_UNIVERSITY: "NON_FUNDED_UNIVERSITY",
  POOR_ACADEMICS: "POOR_ACADEMICS",
  LOAN_CLOSED: "LOAN_CLOSED",
  DISCONTINUED_MASTERS: "DISCONTINUED_MASTERS",
  FRAUDULENT_DOCUMENTS_SUBMITTED: "FRAUDULENT_DOCUMENTS_SUBMITTED",
};

export const DisbursementBlockingReasonDetail = {
  ACADEMIC_SCORE: "ACADEMIC_SCORE",
  NEW_UNIVERSITY_NAME_AND_COURSE: "NEW_UNIVERSITY_NAME_AND_COURSE",
  COUNTRY_DETAILS: "COUNTRY_DETAILS",
  FRAUD_DETECTED: "FRAUD_DETECTED",
  LOAN_CLOSED: "LOAN_CLOSED",
};

export const DisbursementBlockingReasonDetailMapper = {
  NON_FUNDED_UNIVERSITY:
    DisbursementBlockingReasonDetail.NEW_UNIVERSITY_NAME_AND_COURSE,
  POOR_ACADEMICS: DisbursementBlockingReasonDetail.ACADEMIC_SCORE,
  LOAN_CLOSED: DisbursementBlockingReasonDetail.LOAN_CLOSED,
  DISCONTINUED_MASTERS: DisbursementBlockingReasonDetail.COUNTRY_DETAILS,
  FRAUDULENT_DOCUMENTS_SUBMITTED:
    DisbursementBlockingReasonDetail.FRAUD_DETECTED,
};

export const DisbursementReasonValueFieldMapper = {
  NON_FUNDED_UNIVERSITY: ["universityName", "courseName"],
  POOR_ACADEMICS: ["cgpa"],
  DISCONTINUED_MASTERS: ["locationStatus"],
  LOAN_CLOSED: ["loanClosed"],
  FRAUDULENT_DOCUMENTS_SUBMITTED: ["uploadedDocType"],
};
